.roadmap-wrapper {
  width: 90%;
  height: auto;
  display: flex;
  flex-direction: column;
  margin: auto;
  padding-top: 10rem;
}

.roadmap-content {
  width: 100%;
  height: 30%;
  margin: auto;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}

.roadmap-content img {
  width: 350px;
}

.roadmap-content p {
  width: 70%;
  margin: auto;
  text-align: center;
  font-family: "Josefin Sans", sans-serif;
  font-size: 1.35rem;
  font-weight: 300;
  padding-top: 4rem;
}

.roadmap-content h1 {
  color: #131313;
  font-family: "Josefin Sans", sans-serif;
  font-size: 2.5rem;
}

.roadmap-slider {
  width: 90%;
  margin: auto;
  height: 60%;
  padding-top: 5rem;
  /* background-color: #131313; */
}

.container-roadmap {
  /* width: 960px; */
  width: 100%;
  margin: 0 auto;
}

.tabs {
  display: flex;
  justify-content: space-between;
  height: 25px;
}

.tabs button {
  border: none;
  color: #131313;
  display: flex;
  cursor: pointer;
  width: 100%;
  transition: all 0.5s ease-out;
  font-size: 80px;
  height: 20px;
  padding-bottom: 0.5rem;
  justify-content: center;
  align-content: flex-start;
  align-items: center;
}

.tabs button:nth-child(1){
  background: rgb(231,60,53);
  background: linear-gradient(90deg, rgba(231,60,53,0) 0%, rgba(231, 59, 53, 0.473) 8%, rgba(231,60,53,1) 44%, rgba(231,60,53,1) 57%, rgba(231,60,53,1) 100%);
}
.tabs button:nth-child(2){
  background-color: #e73c35; 
}
.tabs button:nth-child(3){
  background-color: #e73c35;
}
.tabs button:nth-child(4){
  background-color: #e73c35;
}
.tabs button:nth-child(5){
  background: rgb(231,60,53);
  background: linear-gradient(90deg, rgba(231,60,53,1) 0%, rgba(231,60,53,1) 8%, rgba(231,60,53,1) 44%, rgba(231,60,53,1) 57%, rgba(231,60,53,0) 100%);
}

/* .tabs button:hover {
  color: white;
  background-color: rgba(255, 255, 255, 0.15);
} */

.tabs button:disabled {
  color: #F2C85B;
}

.title {
  color: #F2C85B;
  font-family: 'Josefin Sans', sans-serif;
  font-size: 2.5rem;
  font-weight: 800;
}

.content-roadmap {
  padding: 50px 80px;
  background-color: #fffcf9;
  font-weight: 300;
  line-height: 30px;
  font-size: 16px;
  text-align: justify;
}

.content-roadmap,
button {
  font-size: 15px;
}

.sliders-fields{
  border: 2px solid #131313;
  width: 100%;
  height: 100%;
  padding: 2rem;
}

.sliders-inner{
  width: 100%;
  height: 40vh;
  margin: auto;
  background-color: #131313;
  display: flex;
}

.sliders-inner img{
  padding: 1rem;
}

.second-inner{
  display: flex;
  flex-direction: column;
  padding: 2rem 1rem 1rem 1rem;
  color: aliceblue;
}

.roadmap-dates{
  width: 100%;
  height: 10vh;
  display: flex;
  justify-content: space-between;
}

.dates-div{
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  font-family: 'Josefin Sans', sans-serif;
}

.dates-div img{
  width: 20%;
  margin-bottom: 1rem;
}

.red-font{
  color: #e73c35;
}

#roadmap-p{
  padding-top: 2rem;
  font-size: 1.1rem;
}

/* @media only screen and (max-width: 960px) {

  .container {
      width: 100%;
  }

  .content {
      padding: 50px;
  }
} */

@media screen and (min-width: 1200px) {
  .content-roadmap{
    padding: 2rem;
  }

  .container-roadmap{
    width: 100%!important;
  }

  .roadmap-slider{
    width: 100%;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1200px) {
  .content-roadmap{
    padding: 2rem;
  }

  .container-roadmap{
    width: 100%!important;
  }

  .roadmap-slider{
    width: 100%;
  }
}

@media screen and (min-width: 481px) and (max-width: 999px) {

  .roadmap-wrapper {
    padding-top: 10rem;
  }

  .content-roadmap{
    padding: 1rem;
    height: auto;
  }

  .container-roadmap{
    width: 100%!important;
  }

  .roadmap-slider{
    width: 100%;
  }

  .roadmap-content img {
    width: 300px;
   }

   .roadmap-content h1 {
    font-size: 2rem;
   }

   .roadmap-content p{
    width: 90%;
   }

   .dates-div h2{
    font-size: 0.8rem;
   }

   .tabs{
    width: 100%;
   }

   .roadmap-dates{
    width: 100%;
   }

   .sliders-fields{
    height: auto;
   }

   .sliders-inner{
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
   }

   .sliders-inner img{
    width: 300px;
   }
}

@media screen and (min-width: 320px) and (max-width: 480px) {

  .roadmap-wrapper {
    width: 100%;
    padding-top: 10rem;
  }

  .content-roadmap{
    margin: 1rem 1rem 1rem 0;
    padding: 0;
    height: auto;
  }

  .container-roadmap {
    width: 90%!important;
  }
  .roadmap-slider{
    width: 100%;
  }

  .roadmap-content img {
    width: 300px;
   }

   .roadmap-content h1 {
    font-size: 2rem;
   }

   .roadmap-content p{
    width: 90%;
   }

   .dates-div h2{
    font-size: 0.7rem;
    transform: rotate(270deg);
    padding-left: 1.7rem;
    width: 120%;
   }

   .tabs{
    width: 90%;
    height: 15px!important;
    margin: auto;
   }

   .tabs button{
    height: 15px;
    font-size: 60px;
   }

   .roadmap-dates{
    width: 90%;
    margin: auto;
   }

   .sliders-fields{
    height: auto;
    padding: 0.5rem;
   }

   .sliders-inner{
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
   }

   .sliders-inner img{
     width: 230px;
   }

   .dates-div img{
    width: 2px;
   }
}

