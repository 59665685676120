*{
  margin: 0;
  padding: 0;
  
}

::-webkit-scrollbar{
  width: 12px;
  background-color: #FFFCF9;
}

::-webkit-scrollbar-thumb{
  background-color: #e73c35;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover{
  background-color: #b9302b;
  transition: 0.5s;
}

::selection{
  background-color: #e73c35;
  color: #FFFCF9;
}

img{
  user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}

.spinner {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-color: #000;

}

.gif {
  width: 100%;
  height: 100vh;
  background: url('./assets/loadgif.gif') no-repeat center center;
  z-index: 10;
}

.spinner span {
  font-size: 2rem;
  animation: fade 5s linear 0s infinite;
  padding-right: 1rem;
}

.half-spinner {
  width: 50px;
  height: 50px;
  border: 3px solid #03fc4e;
  border-top: 3px solid transparent;
  border-radius: 50%;
  animation: spin 0.5s linear 0s infinite;
}

.completed {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;  
  /* background-color: #08191f; */
  background-color: #000;

}

@keyframes bigger {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(2);
  }
}

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fade {
  from {
    opacity: 1;
  }
  to {
    opacity: 1;
  }
}