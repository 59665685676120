.header-wrapper{
    width: 100%;
    height: 105vh;
    background-image: url('../../assets/newbg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    flex-direction: column;
}

.header-content{
    width: 30%;
    height: 50vh;
    /* background-color: black; */
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.header-title{
    color: #E73C35;
    text-align: center;
    font-size: 3.5rem;
    font-family: 'Josefin Sans', sans-serif;
	font-weight: 700;
}

.header-paragraph{
    text-align: center;   
    font-size: 1.25rem;
    font-family: 'Josefin Sans', sans-serif;
	font-weight: 700;
    margin-top: 1rem;
}

.buttons-div{
    width: 100%;
    height: 8vh;
    /* background-color: black; */
    margin-top: 1rem;
    display: flex;
    align-items: center;
}

.first-button{
    display: flex;
    width: 45%;
    height: 90%;
    border: 2px solid #E73C35;
    margin: auto;
    cursor: pointer;
    justify-content: center;
    align-items: center;
}

.first-button a{
    width: 96%;
    height: 90%;
    margin: auto;
}

.second-button{
    display: flex;
    width: 45%;
    height: 90%;
    border: 2px solid #F2C85B;
    margin: auto;
    cursor: pointer;
    justify-content: center;
    align-items: center;
}

.second-button a{
    width: 96%;
    height: 90%;
}

.first-button:hover{
    border: 2px solid #F2C85B;
    transition: 0.3s;
}

.mint-button:hover{
    background-color: #F2C85B;
    transition: 0.3s ease-in-out;
}

.second-button:hover{
    border: 2px solid #E73C35;
    transition: 0.3s;
}

.opensea-button:hover{
    background-color: #E73C35;
    transition: 0.3s ease-in-out;
}

.mint-button{
    width: 100%;
    height: 100%;
    margin: auto;
    background-color: #E73C35;
    border: none;
    font-size: 1.5rem;
	font-family: 'Josefin Sans', sans-serif;
	font-weight: 400;
    color: #FFFFFF;
    cursor: pointer
}

.opensea-button{
    width: 100%;
    height: 100%;
    margin: auto;
    background-color: #F2C85B;
    border: none;
    font-size: 1.5rem;
	font-family: 'Josefin Sans', sans-serif;
	font-weight: 400;
    color: #FFFFFF;
    cursor: pointer
}

.scroll-downs {
    position: absolute;
    margin: auto;
    display: flex;
    top: 75vh;
    left: 49%;
    padding-top: 100px;
}

.mousey {
    width: 3px;
    padding: 10px 15px;
    height: 35px;
    border: 2px solid #E73C35;
    border-radius: 25px;
    opacity: 0.75;
    box-sizing: content-box;
}

.scroller {
    width: 3px;
    height: 10px;
    border-radius: 25%;
    background-color: #E73C35;
    animation-name: scrolls;
    animation-duration: 2.2s;
    animation-timing-function: cubic-bezier(0.15, 0.41, 0.69, 0.94);
    animation-iteration-count: infinite;
}

@keyframes scrolls {
    0% {
      opacity: 0;
    }
    10% {
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      transform: translateY(15px);
      opacity: 0;
  }
}

@media screen and (min-width: 769px) and (max-width: 1060px){
    .header-content{
        width: 45%;
    }
}
@media screen and (min-width: 481px) and (max-width: 768px){

    .header-content{
        width: 65%;
    }
    
    .mint-button, .opensea-button{
        font-size: 1.4rem;
    }
    
}
@media screen and (min-width: 320px) and (max-width: 480px){
    .header-content{
        width: 85%;
    }
    
    .mint-button, .opensea-button{
        font-size: 1.25rem;
    }

    .header-title{
        font-size: 2.5rem;
    }

    .header-paragraph{
        font-size: 1.15rem;
    }
}