*{
    font-family: "Josefin Sans", sans-serif;
}

.faqs-wrapper {
  width: 80%;
  height: auto;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10rem;
}

.faqs-wrapper img {
  width: 350px;
}

.faqs-wrapper h1 {
  color: #131313;
  font-family: 'Josefin Sans', sans-serif;
  font-size: 2.5rem;
}

.faqs-p {
  width: 70%;
  margin: 2rem auto 0 auto;
  text-align: center;
  font-family: "Josefin Sans", sans-serif;
  font-size: 1.35rem;
  font-weight: 300;
  padding-top: 4rem;
}

.container-faq {
  display: flex;
  margin: 0 auto;
  width: 90%;
}
.column {
  display: table-cell;
  padding: 0px 10px;
  width: 50%;
  padding-top: 8rem;
}
.accordion {
  display: block;
  font-size: inherit;
  margin: 10px;
  position: relative;
  border: 2px solid #E73C35;
  border-radius: 10px;
}
.accordion input {
  display: none;
  position: absolute;
  visibility: hidden;
  left: 50%;
  top: 50%;
  z-index: 1;
}
.accordion__header {
  background-color: transparent;
  /* border: 2px solid #E73C35; */
  border-bottom-width: 0px;
  color: #E73C35;
  cursor: pointer;
  transition: background 0.2s;
  padding: 20px;
  position: relative;
  z-index: 2;
}
.accordion__header:hover {
  background-color: #E73C35;
  color: white;
}
.accordion__header:hover:before,
.accordion__header:hover:after {
  background-color: white;
}
.accordion__header:before,
.accordion__header:after {
  background-color: #E73C35;
  content: "";
  display: block;
  position: absolute;
  z-index: 3;
}
.accordion__header:before {
  height: 2px;
  margin-top: -1px;
  top: 50%;
  right: 20px;
  width: 8px;
}
.accordion__header:after {
  height: 8px;
  margin-top: -4px;
  top: 50%;
  right: 23px;
  width: 2px;
}
.accordion input:checked ~ .accordion__header {
  background: #E73C35;
  border-color: #E73C35;
  color: white;
}
.accordion input:checked ~ .accordion__header:hover {
  background-color: #E73C35;
  border-color: #E73C35;
  color: white;
}
.accordion input:checked ~ .accordion__header:before {
  background-color: white;
}
.accordion input:checked ~ .accordion__header:after {
  display: none;
}
.accordion:first-child .accordion__header {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.accordion:last-child .accordion__header {
  border-bottom-width: 1px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.accordion:last-child input:checked ~ .accordion__header {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.accordion__content {
  /* background-color: white; */
  /* border: 1px solid #E73C35; */
  border-width: 0px 1px;
  display: none;
  padding: 20px;
}
.accordion input:checked ~ .accordion__content {
  display: block;
}
.accordion:last-child .accordion__content {
  border-bottom-width: 1px;
  border-radius: 0px 0px 4px 4px;
}
.accordion__content h6 {
  color: #E73C35;
  font-size: 18px;
  margin-bottom: 5px;
}

@media screen and (min-width:769px) and (max-width:1050px){
  .container-faq{
    width: 100%;
  }

  .faqs-p {
    width: 90%;
    padding-top: 0;
  }
}

@media screen and (min-width: 481px) and (max-width:768px){
  .container-faq{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .column{
    width: 80%;
  }

  .column-padding{
    padding-top: 1rem;
  }

  .faqs-p {
    width: 95%;
    padding-top: 0;
  }

  .faqs-wrapper img {
    width: 300px;
  }
  
  .faqs-wrapper h1 {
    font-size: 2rem;
  }
}

@media screen and (min-width: 320px) and (max-width:480px){

  .faqs-wrapper {
    width: 90%;
  }

  .container-faq{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .column{
    width: 100%;
  }

  .column-padding{
    padding-top: 1rem;
  }

  .faqs-p {
    width: 100%;
    padding-top: 0;
  }

  .faqs-wrapper img {
    width: 300px;
  }
  
  .faqs-wrapper h1 {
    font-size: 2rem;
  }
}