.gamepage-wrapper{
    width: 100%;
    height: 100vh;
    background-image: url("../../assets/gamepagebg.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.gamepage-content{
    width: 100%;
    height: auto;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'Josefin Sans', sans-serif;
	font-weight: 700;
    padding-top: 3rem;
}

.gamepage-content h1{
    padding-top: 1rem;
    /* border-bottom: 3px solid #E73C35; */
    /* box-shadow: inset 0px 0px 10px #e73c35; */
}

.gamepage-content a{
    padding-top: 5rem;
    color: white;
    border-bottom:3px solid #e73c35;
    font-size: 1.5rem;
    text-decoration: none;
}

@media screen and (max-width: 760px) {
    .gamepage-content h1{
        text-align: center;
        font-size: 1.4rem;
    }
}