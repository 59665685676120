.trailer-wrapper{
    width: 80%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    padding-top: 10rem;
}

.trailer-wrapper img{
    width: 350px;
}

.trailer-wrapper h1{
    color: #131313;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 2.5rem;
}

.video-div{
    width: 80%;
    height: 80%;
    margin: auto;
    padding-top: 5rem;
}

.trailer-vid{
    width: 100%;
    height: 66vh;
    border: 4px solid #e73c35;
}

@media screen and (min-width:769px) and (max-width:1024px){
    .video-div {
        width: 90%;
        height: 90%;
        margin-top: 4rem;
    }

    .trailer-vid{
        width: 100%;
        height: 50vh;
    }
}

@media screen and (min-width:550px) and (max-width:768px){
    .video-div {
        width: 100%;
        height: 100%;
        margin-top: 4rem;
    }

    .trailer-wrapper img{
        width: 300px;
    }
    
    .trailer-wrapper h1{
        font-size: 2rem;
    }
    .trailer-vid{
        width: 100%;
        height: 40vh;
    }
}

@media screen and (min-width:320px) and (max-width:549px){
    .video-div {
        width: 100%;
        height: 50%;
    }

    .trailer-wrapper img{
        width: 300px;
    }
    
    .trailer-wrapper h1{
        font-size: 2rem;
    }
    .trailer-vid{
        width: 100%;
        height: 30vh;
    }
}
/* 
@media screen and (min-width:768px) and (max-width:1024px){
    
} */
