* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

.aActive {
	border-bottom: 2px solid green;
}

html {
	scroll-behavior: smooth;
}

nav {
	position: fixed;
	z-index: 10;
	left: 0;
	right: 0;
	top: 10px;
	background-color: transparent;
	display: flex;
	justify-content: center;
}

.active {
	border-bottom: 2px solid #000000;
	transition: .1s ease-in;
}

nav .logo {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0px 50px;
	user-select: none;
	-webkit-user-drag: none;
}

nav .links {
	padding: 0 0 0 6%;
	margin: 0;
	display: flex;
	align-items: center;
}

nav .links li {
	list-style: none;
	padding-right: 17px;
}

nav .links li:nth-child(2) {
	padding-right: 17px;
}

nav .links li:nth-child(3) {
	padding-right: 0px;
}

nav .links li a {
	display: block;
    font-size: 15px;
    font-weight: bold;
    text-decoration: none;
    color: #131313;
    font-style: normal;
    border: 3px solid #E73C35;
    padding: 0.5rem 1rem 0.4rem;
    background-color: #FFFFFF;
	text-transform: uppercase;
	font-family: 'Josefin Sans', sans-serif;
	font-weight: 400;
}

#svg-nav{
	font-size: 25px;
	margin: 0.3rem;
	color: #000!important;
    text-decoration: none;
    border: none!important;
    padding: 0!important;
    background-color: transparent!important;
}

nav .links li a:hover{
	background-color: #E73C35;
	border: 3px solid #FFFCF9;
	color: #FFFCF9;
	transition: 0.5s ease-in-out;
}


#nav-toggle {
	position: absolute;
	top: -100px;
}

nav .icon-burger {
	display: none;
	position: absolute;
	right: 5%;
	top: 50%;
	transform: translateY(-50%);
}

nav .icon-burger .line {
	width: 30px;
	height: 5px;
    background-color: #e73c35;
	margin: 5px;
	border-radius: 3px;
	transition: all .3s ease-in-out;
}

.logo-mobile {
	display: none;
}

.icons-row{
	display: flex;
}

.icons-row svg {
	font-size: 25px;
	margin: 0.3rem;
	color: #000;
}

.icons-row svg:hover{
	border-bottom: 1px solid black;
	transition: 0.5s ease-in-out;
}

@media screen and (max-width: 992px) {
	nav {
		top: 0!important;
		background-color: #FFFFFF!important;
		padding: 0!important;
	}

	nav .logo {
		display: none;
	}

	.logo-mobile {
		display: block;
		padding: 1rem;
	}

	.logo-mobile img {
		width: 75px;
	}

	nav .links {
		float: none;
		position: fixed;
		z-index: 9;
		left: 0;
		right: 0;
		top: 114px;
		bottom: 100%;
		flex-direction: column;
		justify-content: space-evenly;
		align-items: center;
		background-color: white;
		overflow: hidden;
		box-sizing: border-box;
		transition: all .5s ease-in-out;
		height: 0px;
		align-items: center;
	}

	nav .links li {
		padding: 0;
	}
	nav .links li a{
		border: none;
		border-bottom: 3px solid #E73C35!important;
	}

	nav .links a {
		font-size: 20px;
		border: none;
		text-align: center;
	}

	nav :checked~.links {
		bottom: 0;
		height: 400px;
	}

	nav .links li:nth-child(2) {
		padding-right: 0px;
	}

	nav .icon-burger {
		display: block;
		color: black;
		cursor: pointer;
	}

	nav :checked~.icon-burger .line:nth-child(1) {
		transform: translateY(10px) rotate(225deg);
	}

	nav :checked~.icon-burger .line:nth-child(3) {
		transform: translateY(-10px) rotate(-225deg);
	}

	nav :checked~.icon-burger .line:nth-child(2) {
		opacity: 0;
	}

}