.footer-wrapper {
    height: 110vh;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: absolute;
    margin-top: 4rem;
    justify-content: flex-end;
  }
  
  .marquee-wrapper {
    min-height: 110vh;
    position: absolute;
    display: grid;
    align-content: center;
    overflow: hidden;
    /* gap: 2rem; */
    width: 100%;
    font-family: "Corben", system-ui, sans-serif;
    padding-bottom: 17%;
    /* transform: skewY(342deg); */
  }
  
  .marquee {
    --duration: 10s;
    --gap: 0.5rem;
  
    display: flex;
    overflow: hidden;
    user-select: none;
    /* gap: 2rem; */
    transform: skewY(342deg);
    /* transform: rotate(342deg); */
    width: 100%;
  }
  
  .marquee__group {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 0.5rem;
    min-width: 100%;
    animation: scroll 20s linear infinite;
  }
  
  @media (prefers-reduced-motion: reduce) {
    .marquee__group {
      animation-play-state: paused;
    }
  }
  
  .marquee__group img {
    /* max-width: clamp(10rem, 1rem + 28vmin, 20rem); */
    width: 250px;
    aspect-ratio: 1;
    object-fit: cover;
    border-radius: 1rem;
    /* transform: skewY(-342deg); */
    /* transform: rotate(342deg); */
  }
  
  .marquee__group p {
    background-image: linear-gradient(
      75deg,
      #f3e068 0%,
      #f3e068 11%,
      #f3e068 22%,
      #f3e068 33%,
      #f3e068 44%,
      #f3e068 56%,
      #f3e068 67%,
      #f3e068 78%,
      #f3e068 89%,
      #f3e068 100%
    );
  
    -webkit-text-fill-color: transparent;
    font-size: 2rem;
    font-weight: 900;
  }
  
  .marquee--borders {
    border-block: 3px solid #f3e068;
    padding-block: 0.75rem;
  }
  
  .marquee--reverse .marquee__group {
    animation-direction: reverse;
    animation-delay: calc(0s);
  }
  
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
  
    100% {
      transform: translateX(calc(-100% - 2rem));
    }
  }
  
  .footer-content {
    width: 100%;
    position: absolute;
    display: contents;
  }
  
  .footer-content img {
    width: 100%;
    z-index: 1;
  }
  
  .footer-content p {
    position: a;
  }

    @media screen and (min-width: 320px) and (max-width:479px){
      .marquee-wrapper{
        padding-top: 75%!important;
      }
      .marquee__group img {
        width: 200px;
      }
    }
    @media screen and (min-width: 480px) and (max-width:767px){
      .marquee-wrapper{
        padding-top: 7%!important;
      }
      
    }
    @media screen and (min-width: 768px)  and (max-width:991px){
      .marquee-wrapper{
        padding-bottom: 8%!important;
      }
    }
    @media screen and (min-width: 992px)  and (max-width:1199px){
      .marquee-wrapper{
        padding-bottom: 13%!important;
      }
    }
    @media screen and (min-width: 1200px) and (max-width: 1300px){
      .marquee-wrapper{
        padding-bottom: 17%!important;
      }
    }
    @media screen and (min-width: 1301px){
      .marquee-wrapper{
        padding-bottom: 19%!important;
      }
    }