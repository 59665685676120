*{
  font-family: "Josefin Sans", sans-serif;
}

.mint-wrapper {
  background-image: url("../src/assets/newbg.png"); 
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  justify-content: center;
  align-items: center;
}

.container {
  display: flex;
  flex: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-size: cover;
  background-position: center;
  /* background-color: #000000; */
}

.first-p {
  color: whitesmoke;
  font-size: 22px;
  font-weight: 500;
  line-height: 1.6;
  /* text-shadow: 0px 0px 8px #000000; */
}

.second-p {
  color: whitesmoke;
  font-size: 18px;
  line-height: 1.6;
  text-shadow: 0px 0px 8px #000000;
}

.third-p {
  color: whitesmoke;
  font-size: 16px;
  line-height: 1.6;
  text-shadow: 0px 0px 8px #000000;
}

.btn-btn {
  margin: 10px;
  width: 170px;
  height: 60px;
  padding: 15px 30px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 10px;
  display: block;
  border: 0px;
  /* // font-weight: 700; */
  font-size: 23px;
  box-shadow: 0px 0px 14px -7px #f01919;
  background-image: linear-gradient(90deg,#ff2f2f 0%,#ff2f2f 40%, #cfa0a0 51%,#ff2f2f 100%);
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.btn-btn:active {
  transform: scale(0.95);
}

.btn-btn:hover {
  background-position: right center;
  color: #fff;
  text-decoration: none;
}

.round-btn {
  border-radius: 100%;
  border: none;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  display: block;
  border: 0px;
  text-align: center;
  font-size: 18px;
  box-shadow: 0px 0px 14px -7px #f01919;
  background-image:linear-gradient(90deg,#ff2f2f 0%,#ff2f2f 40%, #cfa0a0 51%,#ff2f2f 100%);
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.round-btn:active {
  transform: scale(0.95);
}

.round-btn:hover {
  background-position: right center;
  color: #fff;
  text-decoration: none;
}

.responsive-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 80%;
  border-radius: 20px;
  text-shadow: 0px 0px 10px red;
  color: whitesmoke;
  background-color: rgba(0, 0, 0, 0.637);
  box-shadow: 0px 5px 11px 2px rgb(255, 0, 0);
}

#flexrowbuttons{
  display: flex!important;
  flex-direction: row!important;
}

@media (min-width: 767px) {
  .responsive-container {
    flex-direction: row;
  }
}

@media (max-width:600px) {
  .responsive-contaner{
    width: 90%!important;
  }
}

.first-a{
    color: whitesmoke;
    text-decoration: none;
    border-bottom: 3px solid #e73c35;
}

.second-a{
    color: whitesmoke;
    text-decoration: none;
}


.goback{
    color: white;
    font-size: 30px;
}

.goback:hover{
    color: #ff352e;
    transition: 0.3s;
    /* border-bottom: 3px solid #e73c35; */
}

.goback-span{
    text-decoration: none;
    font-size: 24px;
    padding-left: 3px;
}