.game-wrapper {
  width: 80%;
  height: auto;
  display: flex;
  flex-direction: column;
  margin: auto;
  /* justify-content: center; */
  align-items: center;
  padding-top: 10rem;
}

.game-wrapper img {
  width: 350px;
}

.game-wrapper h1 {
  color: #131313;
  font-family: "Josefin Sans", sans-serif;
  font-size: 2.5rem;
}

.game-wrapper p {
  width: 70%;
  text-align: center;
  font-family: "Josefin Sans", sans-serif;
  font-size: 1.35rem;
  font-weight: 300;
  padding-top: 3.5rem;
}

.game-photos {
  width: 100%;
  height: 70%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 2rem;
}

.photo {
  width: 450px;
  height: 350px;
  border-radius: 25px;
  margin: 1rem;
}

.photo img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  box-shadow: 0px 0px 800px #af2d2d;
}

.game-button {
  display: flex;
  width: 250px;
  height: 65px;
  border: 2px solid #e73c35;
  margin: 1rem auto 0 auto;
  cursor: pointer;
}

.game-button:hover {
  border: 2px solid #f2c85b;
  transition: 0.3s;
  cursor: pointer;
}

.playgame-button:hover {
  background-color: #f2c85b;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}

.game-button a {
  width: 96%;
  height: 90%;
  margin: auto;
  cursor: pointer;
}

.playgame-button {
  width: 100%;
  height: 100%;
  margin: auto;
  background-color: #e73c35;
  border: none;
  font-size: 1.5rem;
  font-family: "Josefin Sans", sans-serif;
  font-weight: 400;
  color: #ffffff;
}

.game-content {
  width: 90%;
  height: auto;
  margin: auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

@media screen and (min-width: 1100px) and (max-width: 1350px) {
  .game-content {
    width: 100%;
  }

  .photo {
    width: 400px;
    height: 300px;
  }
}

@media screen and (min-width: 960px) and (max-width: 1099px) {
  .game-content {
    width: 100%;
  }

  .photo {
    width: 380px;
    height: 300px;
  }
}

@media screen and (max-width: 1250px) {
  .game-wrapper {
    height: auto;
  }
}

@media screen and (max-width: 768px) {
  .game-wrapper img {
    width: 100%;
  }

  .game-wrapper h1 {
    color: #131313;
    font-family: "Josefin Sans", sans-serif;
    font-size: 2rem;
  }

  .game-wrapper p {
    width: 100%;
  }
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .game-content {
    width: 100%;
  }

  .photo {
    width: 400px;
    height: 220px;
  }
}
