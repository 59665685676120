.about-wrapper{
    width: 80%;
    height: 70vh;
    background-color: #FFFCF9;
    margin: auto;
    display: flex;
    flex-direction: column;
    padding-top: 10rem;
}

.about-title{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.about-title h1{
    color: #131313;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 2.5rem;
}

.about-title img {
    width: 350px;
}

.about-content{
    width: 90%;
    height: 75%;
    margin: auto;
    display: flex;
}

.about-p{
    width: 60%;
    height: 100%;
    display: flex;
    align-items: center;
    text-align: justify;
}

.about-p p{
    width: 80%;
    margin: auto;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 1.35rem;
    font-weight: 300;
}

.about-slider{
    width: 40%;
    height: 100%;
    display: flex;
    align-items: center;
}

.swiper {
    width: 300px;
    /* height: 300px; */
    padding: 50px;
  }
  
  .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 300px;
    height: 300px;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
  }

  .swiper-wrapper{

  }
.swiper-button-next{
    display: none!important;
}

.swiper-button-prev{
    display: none!important;
}

.swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
    bottom: -35px!important;
}

.swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet{
    width: 20px!important;
    border-radius: 0!important;
    height: 3px!important;
}

.swiper-pagination-bullet-active{
    background: #E73C35!important;
}

.swiper-pagination-bullet{
    background: #dd625d!important;
}

.swiper{
    padding: 0.5rem!important;
    border: 2px solid black!important;
}

@media screen and (min-width: 769px) and (max-width: 1060px){
    .about-p p{
     margin-left: 0;
    }
 
    .about-wrapper{
     width: 90%;
     height: 90vh;
    }
 }

 @media screen and (min-width: 481px) and (max-width: 768px){

    .about-p{
        width: 80%;
    }
    
    .about-slider{
        width: 80%;
    }

    .about-p p {
        width: 100%;
        padding-top: 2rem;
        padding-bottom: 2rem;
    }

   .about-wrapper{
    width: 100%;
    height: auto;
   }

   .about-content{
    display: flex;
    flex-direction: column;
    height: auto;
    align-items: center;
   }

   .about-title img {
    width: 300px;
   }

   .about-title h1 {
    color: #131313;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 2rem;
   }
}

@media screen and (min-width: 320px) and (max-width: 480px){

    .about-p{
        width: 90%;
    }
    
    .about-slider{
        width: 90%;
    }

    .about-p p {
        width: 100%;
        padding-top: 2rem;
        padding-bottom: 2rem;
    }

   .about-wrapper{
    width: 100%;
    height: auto;
   }

   .about-content{
    display: flex;
    flex-direction: column;
    height: auto;
    align-items: center;
   }

   .about-title img {
    width: 300px;
   }

   .about-title h1 {
    color: #131313;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 2rem;
   }
}